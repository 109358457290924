import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { RouterProvider } from "react-router-dom";
import router from "./router";
import App from "./App";
import { ClerkProvider, useAuth } from "@clerk/clerk-react";
import { ConvexProviderWithClerk } from "convex/react-clerk";
import { ConvexReactClient } from "convex/react";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
const root = ReactDOM.createRoot(document.getElementById("root"));
const convex = new ConvexReactClient(process.env.REACT_APP_CONVEX_URL || "");
const posthogKey = process.env.REACT_APP_PUBLIC_POSTHOG_KEY;
const posthogHost = process.env.REACT_APP_PUBLIC_POSTHOG_HOST;
if (posthogKey && posthogHost) {
    posthog.init(posthogKey, {
        api_host: posthogHost,
    });
}
const Providers = ({ children }) => (_jsx(ClerkProvider, Object.assign({ publishableKey: process.env.REACT_APP_CLERK_PUBLISHABLE_KEY || "" }, { children: _jsx(ConvexProviderWithClerk, Object.assign({ client: convex, useAuth: useAuth }, { children: _jsx(PostHogProvider, Object.assign({ client: posthog }, { children: children })) })) })));
root.render(_jsx(React.StrictMode, { children: _jsx(Providers, { children: _jsx(App, { children: _jsx(RouterProvider, { router: router }) }) }) }));
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
