var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { api, useMutation } from "@bot-prospect/convex";
import { Box, Button, Checkbox, Container, FormControlLabel, InputLabel, MenuItem, Select, Stack, Typography, } from "@mui/material";
import { useId } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import FormInput from "../components/FormInput";
const INDUSTRIES = ["Automotive", "Banking", "Financial Products"];
const POTENTIAL_ROLES = [
    "SDR Manager",
    "SDR",
    "AE",
    "Director of Sales",
    "CEO",
    "Sales Engineer",
];
const Onboarding = () => {
    var _a, _b, _c, _d, _e;
    const { register, setValue, watch, handleSubmit, formState: { errors }, } = useForm({
        defaultValues: {
            firstName: "",
            lastName: "",
            reasonToJoin: "",
            role: "",
            willingToProvideFeedback: false,
            industry: "",
        },
        mode: "onChange",
    });
    const onboardUser = useMutation(api.users.onboardUser);
    const role = watch("role");
    const industry = watch("industry");
    const willingToProvideFeedback = watch("willingToProvideFeedback");
    const selectLabelId = useId();
    const navigate = useNavigate();
    const onSubmit = (data) => __awaiter(void 0, void 0, void 0, function* () {
        yield onboardUser(data);
        navigate("/");
    });
    return (_jsx(Container, Object.assign({ maxWidth: "sm" }, { children: _jsxs(Stack, Object.assign({ direction: "column", my: 8, gap: 3 }, { children: [_jsxs(Box, Object.assign({ alignSelf: "center" }, { children: [_jsx(Typography, Object.assign({ component: "div", variant: "h3", marginBottom: 3 }, { children: "Welcome to Bot Prospect" })), _jsx(Typography, Object.assign({ component: "div", fontSize: "large", variant: "body2", marginBottom: 3 }, { children: "Before diving into the world of bots, could you help us out with something?" })), _jsxs(Stack, Object.assign({ direction: "column", gap: 4 }, { children: [_jsxs(Stack, Object.assign({ direction: "row", gap: 4, justifyContent: "space-between" }, { children: [_jsx(FormInput, Object.assign({ label: "First name", placeholder: "Your first name \uD83D\uDE4F" }, register("firstName", {
                                            required: {
                                                value: true,
                                                message: "Required field",
                                            },
                                        }), { error: !!errors.firstName, helperText: (_a = errors.firstName) === null || _a === void 0 ? void 0 : _a.message })), _jsx(FormInput, Object.assign({ label: "Last name", placeholder: "Last name \uD83E\uDD79" }, register("lastName", {
                                            required: {
                                                value: true,
                                                message: "Required field",
                                            },
                                        }), { error: !!errors.lastName, helperText: (_b = errors.lastName) === null || _b === void 0 ? void 0 : _b.message }))] })), _jsx(FormInput, Object.assign({ label: "How did you find us?", placeholder: "I found you on youtube!" }, register("reasonToJoin", {
                                    required: {
                                        value: false,
                                        message: "Need a chat name so you can remember later!",
                                    },
                                }), { error: !!errors.reasonToJoin, helperText: (_c = errors.reasonToJoin) === null || _c === void 0 ? void 0 : _c.message })), _jsxs(Stack, Object.assign({ direction: "column", spacing: 1 }, { children: [_jsx(InputLabel, Object.assign({ id: selectLabelId }, { children: "Title" })), _jsx(Select, Object.assign({ labelId: selectLabelId, inputProps: { "aria-label": "Without label" }, displayEmpty: true, value: role, onChange: (e) => e.target && setValue("role", e.target.value) }, { children: [...POTENTIAL_ROLES, "Other"].map((role, i) => (_jsx(MenuItem, Object.assign({ value: role, sx: { fontSize: 18 } }, { children: role }), i))) }))] })), role === "Other" && (_jsx(FormInput, Object.assign({ label: "Other", placeholder: "So what do you do for work?" }, register("otherRole", {
                                    required: {
                                        value: true,
                                        message: "Please fill this one in 🥹",
                                    },
                                }), { error: !!errors.otherRole, helperText: (_d = errors.otherRole) === null || _d === void 0 ? void 0 : _d.message }))), _jsxs(Stack, Object.assign({ direction: "column", spacing: 1 }, { children: [_jsx(InputLabel, Object.assign({ id: selectLabelId }, { children: "Industry" })), _jsx(Select, Object.assign({ labelId: selectLabelId, inputProps: { "aria-label": "Without label" }, displayEmpty: true, value: industry, onChange: (e) => e.target && setValue("industry", e.target.value) }, { children: [...INDUSTRIES, "Other"].map((role, i) => (_jsx(MenuItem, Object.assign({ value: role, sx: { fontSize: 18 } }, { children: role }), i))) }))] })), industry === "Other" && (_jsx(FormInput, Object.assign({ label: "Other", placeholder: "Space internet" }, register("otherIndustry", {
                                    required: {
                                        value: true,
                                        message: "Please fill this one in 🥹",
                                    },
                                }), { error: !!errors.otherRole, helperText: (_e = errors.otherRole) === null || _e === void 0 ? void 0 : _e.message }))), _jsxs(Stack, Object.assign({ direction: "row", alignItems: "center" }, { children: [_jsx(Typography, Object.assign({ mb: 0, fontSize: "large", component: "div", variant: "body2" }, { children: "Do you mind giving us feedback on the app?" })), _jsx(FormControlLabel, { onChange: (e, checked) => setValue("willingToProvideFeedback", checked), sx: { alignSelf: "center" }, control: _jsx(Checkbox, { sx: { "& .MuiSvgIcon-root": { fontSize: 32 } } }), value: willingToProvideFeedback, label: _jsx(Typography, Object.assign({ mb: 0, component: "div", fontSize: "large", fontWeight: "bold", variant: "body2" }, { children: "I don't mind!" })), labelPlacement: "start" })] }))] }))] })), _jsx(Button, Object.assign({ onClick: handleSubmit(onSubmit), sx: { alignSelf: "center" }, variant: "contained", size: "large" }, { children: "Lets go!" }))] })) })));
};
export default Onboarding;
