import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { toTitle } from "@bot-prospect/common";
import { Stack, Avatar, Typography, CardContent, useTheme, CardActions, Button, Grid, } from "@mui/material";
import botPhotoPlaceholder from "../../images/bot-avatar.png";
import { StyledBotCard } from "./BotCard.styles";
const colorOptions = ["primary", "secondary", "warning", "success"];
const getColorFromId = (id) => {
    const charCode = id.charCodeAt(1);
    const colorIndex = charCode % 4;
    return colorOptions[colorIndex];
};
const BotCard = ({ bot }) => {
    var _a;
    const theme = useTheme();
    console.log(bot._id);
    const color = getColorFromId(bot === null || bot === void 0 ? void 0 : bot._id);
    return (_jsx(Grid, Object.assign({ item: true, xs: 3, minWidth: '200px', maxWidth: '230px' }, { children: _jsxs(StyledBotCard, Object.assign({ variant: "outlined", sx: {
                backgroundColor: ((_a = theme.palette) === null || _a === void 0 ? void 0 : _a[color].light) || theme.palette.secondary.light,
            } }, { children: [_jsx(CardContent, { children: _jsxs(Stack, Object.assign({ direction: "column", rowGap: 3, alignItems: "center" }, { children: [_jsx(Avatar, Object.assign({ src: bot.profileImageUrl || botPhotoPlaceholder, sx: {
                                    width: 86,
                                    height: 86,
                                } }, { children: bot.name[0] })), _jsxs(Stack, Object.assign({ direction: "column", rowGap: 1, textAlign: "center" }, { children: [_jsx(Typography, Object.assign({ variant: "h6", lineHeight: "130%" }, { children: bot.name })), _jsx(Typography, Object.assign({ variant: "body2", lineHeight: "150%", textOverflow: 'ellipsis' }, { children: toTitle(bot === null || bot === void 0 ? void 0 : bot.title) }))] }))] })) }), _jsx(CardActions, { children: _jsx(Button, Object.assign({ variant: "default" }, { children: "Message" })) })] })) })));
};
export default BotCard;
