import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { Authenticated, Unauthenticated, AuthLoading } from "convex/react";
import { useUser } from "../hooks/useUser";
import LoadingScreen from "./LoadingScreen";
const HasOnboarded = ({ children }) => {
    const user = useUser();
    const location = useLocation();
    if (user && !user.onboarded && location.pathname !== "/onboarding") {
        console.log("should be onboarding");
        return _jsx(Navigate, { to: "/onboarding" });
    }
    else {
        return _jsx(_Fragment, { children: children });
    }
};
const AuthLayout = () => {
    // let user = useUser();
    // console.log(user);
    return (_jsxs(_Fragment, { children: [_jsx(Authenticated, { children: _jsx(HasOnboarded, { children: _jsx(Outlet, {}) }) }), _jsx(AuthLoading, { children: _jsx(LoadingScreen, {}) }), _jsx(Unauthenticated, { children: _jsx(Navigate, { replace: true, to: "/sign-in" }) })] }));
};
export default AuthLayout;
