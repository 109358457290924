import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Toolbar, styled, } from "@mui/material";
import Header from "./Header";
import Sidebar from "./Sidebar";
const MainContainer = styled(Box) `
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;
const LayoutContainer = styled(Box) `
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  min-width: 1280px;
  max-width: 1920px;
`;
const Layout = ({ children }) => {
    // - For the range of screen widths, let's design for 1280×720 through 1920×1080. We can add a max and min widths to keep the app in this range.
    // flexBasis={1280} - ?
    const drawerWidth = 280;
    return (_jsxs(LayoutContainer, { children: [_jsx(Header, {}), _jsxs(Box, Object.assign({ id: "hello", display: 'flex', width: '100%', columnGap: 2 }, { children: [_jsx(Sidebar, { width: drawerWidth }), _jsxs(MainContainer, Object.assign({ component: 'main' }, { children: [_jsx(Toolbar, { sx: { my: 3, flexShrink: 0 } }), _jsx(Box, Object.assign({ minWidth: "400px", display: 'flex', flexDirection: 'column', flexGrow: 1 }, { children: children }))] }))] }))] }));
};
export default Layout;
