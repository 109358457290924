var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Disposition, Objection, ProspectTitle, HappinessLevel, } from "@bot-prospect/common";
import { api, useAction, useMutation } from "@bot-prospect/convex";
import { MenuItem, Select, InputLabel, Slider, Typography, Button, Stack, } from "@mui/material";
import botPhotoPlaceholder from "../images/bot-avatar.png";
import { useCallback, useEffect, useId, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import FormInput from "../components/FormInput";
import { CreateBotAvatar } from "../components/CreateBotAvatar";
import Layout from "../components/Layout";
const HappyLevels = [
    { threshold: 10, happySetting: HappinessLevel.ASSHOLE },
    { threshold: 30, happySetting: HappinessLevel.BAD_DAY },
    { threshold: 60, happySetting: HappinessLevel.REGULAR },
    { threshold: 80, happySetting: HappinessLevel.FRIENDLY },
    { threshold: 99, happySetting: HappinessLevel.BUBBLY },
    { threshold: 100, happySetting: HappinessLevel.ANGEL },
];
const scoreToHappinessLevel = (score) => {
    for (let i = 0; i < HappyLevels.length; i++) {
        const level = HappyLevels[i];
        if (level.threshold >= score) {
            return level.happySetting;
        }
    }
    return HappinessLevel.BUBBLY;
};
const HappyLabels = {
    [HappinessLevel.ASSHOLE]: "An asshole that didn't have their coffee",
    [HappinessLevel.BAD_DAY]: "Someone having a bad day",
    [HappinessLevel.REGULAR]: "A regular sounding person. Might be feeling the Mondays.",
    [HappinessLevel.FRIENDLY]: "Friendly",
    [HappinessLevel.BUBBLY]: "Bubbly, loves you",
    [HappinessLevel.ANGEL]: "An angel",
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
const AddBot = () => {
    var _a, _b;
    const [objections, setObjections] = useState([]);
    const [title, setTitle] = useState("");
    const navigate = useNavigate();
    const addBot = useMutation(api.bots.addBot);
    const labelId_1 = useId();
    const labelId_2 = useId();
    const sliderLabelId = "happiness-level";
    const getRandomProfileImage = useAction(api.images.getRandomBotImage);
    const [avatar, setAvatar] = useState();
    const { register, setValue, handleSubmit, formState: { errors }, } = useForm({
        mode: "onChange",
    });
    const chooseNewPhoto = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        const profileImage = yield getRandomProfileImage();
        setAvatar({
            profileImageId: profileImage.imageId,
            profileImageUrl: profileImage.profileImageUrl,
        });
    }), [getRandomProfileImage]);
    useEffect(() => {
        chooseNewPhoto();
    }, [chooseNewPhoto]);
    useEffect(() => {
        setValue("objections", objections);
    }, [objections, setValue]);
    useEffect(() => {
        setValue("title", title);
    }, [title, setValue]);
    const [happyScore, setHappyScore] = useState(40);
    const [happyLabel, setHappyLabel] = useState("");
    const [happinessLevel, setHappinessLevel] = useState(scoreToHappinessLevel(happyScore));
    useEffect(() => {
        const happinessLevel = scoreToHappinessLevel(happyScore);
        setHappinessLevel(happinessLevel);
        setHappyLabel(HappyLabels[happinessLevel]);
    }, [happyScore, setHappyLabel]);
    const onSubmit = (values) => __awaiter(void 0, void 0, void 0, function* () {
        const { name, title, objections, extra } = values;
        yield addBot({
            name,
            title,
            objections,
            extra,
            disposition: Disposition.DISAGREEABLE,
            happinessLevel,
            profileImageId: avatar === null || avatar === void 0 ? void 0 : avatar.profileImageId,
        });
        navigate("/");
    });
    // -- uploads the file from user's device and render it as Bot's Avatar --
    // const [uploadedAvatar, setUploadedAvatar] = useState<string | ArrayBuffer | null>()
    // const handleFileUpload = (file: Blob) => {
    //   const reader = new FileReader();
    //   reader.onloadend = () => {
    //     setUploadedAvatar(reader.result);
    //   };
    //   reader.readAsDataURL(file);
    // };
    // const avatar = watch('avatar')?.[0]
    // useEffect(() => {
    //   if (avatar !== undefined) {
    //     handleFileUpload(avatar)
    //   }
    // }, [avatar])
    return (_jsx(Layout, { children: _jsxs(Stack, Object.assign({ component: "form", onSubmit: handleSubmit(onSubmit), maxWidth: 640, rowGap: 7.5, mb: 8 }, { children: [_jsx(CreateBotAvatar, Object.assign({ avatar: (avatar === null || avatar === void 0 ? void 0 : avatar.profileImageUrl) || botPhotoPlaceholder }, { children: _jsx(Button, Object.assign({ onClick: chooseNewPhoto, variant: "contained", size: "large" }, { children: "New avatar" })) })), _jsxs(Stack, Object.assign({ rowGap: 5 }, { children: [_jsx(FormInput, Object.assign({ label: "Name", placeholder: "John Smith" }, register("name", {
                            required: {
                                value: true,
                                message: "Need a name!",
                            },
                        }), { error: !!errors.name, helperText: (_a = errors.name) === null || _a === void 0 ? void 0 : _a.message })), _jsx(FormInput, Object.assign({ label: "Company name", placeholder: "John Smith" }, register("company", {
                            required: {
                                value: true,
                                message: "Need a company name!",
                            },
                        }), { error: !!errors.name, helperText: (_b = errors.name) === null || _b === void 0 ? void 0 : _b.message })), _jsxs(Stack, Object.assign({ direction: "column", spacing: 1 }, { children: [_jsx(InputLabel, Object.assign({ id: labelId_1 }, { children: "Manager" })), _jsx(Select, Object.assign({ labelId: labelId_1, inputProps: { "aria-label": "Without label" }, placeholder: "Manager", displayEmpty: true, value: title, onChange: (event) => {
                                        if (event.target.value) {
                                            setTitle(event.target.value);
                                        }
                                    }, error: !!errors.title }, { children: Object.entries(ProspectTitle).map((entry, i) => {
                                        const [key, value] = entry;
                                        return (_jsx(MenuItem, Object.assign({ value: key }, { children: value }), i));
                                    }) }))] })), _jsxs(Stack, Object.assign({ direction: "column", spacing: 1 }, { children: [_jsx(InputLabel, Object.assign({ id: labelId_2 }, { children: "Objections" })), _jsx(Select, Object.assign({ multiple: true, labelId: labelId_2, value: objections, displayEmpty: true, placeholder: "Too expensive", onChange: (event) => {
                                        setObjections(event.target.value);
                                    }, MenuProps: MenuProps }, { children: Object.entries(Objection).map((entry, i) => {
                                        const [key, value] = entry;
                                        return (_jsx(MenuItem, Object.assign({ value: key }, { children: value }), i));
                                    }) }))] })), _jsxs(Stack, Object.assign({ rowGap: 1 }, { children: [_jsx(Typography, Object.assign({ variant: "body1", id: sliderLabelId, sx: { cursor: "default" }, gutterBottom: true }, { children: "Happiness Level" })), _jsx(Slider, { value: happyScore, "aria-labelledby": sliderLabelId, onChange: (event, newValue) => {
                                        setHappyScore(newValue);
                                    } }), happyLabel && (_jsx(Typography, Object.assign({ variant: "subtitle1", gutterBottom: true }, { children: happyLabel })))] })), _jsx(FormInput, Object.assign({ multiline: true, rows: 4, label: "What else might they say?", placeholder: "Competitor's interface is better" }, register("extra")))] })), _jsx(Button, Object.assign({ type: "submit", variant: "contained", size: "large" }, { children: "Add Prospect" }))] })) }));
};
export default AddBot;
