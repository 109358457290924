var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMutation } from "convex/react";
import { useEffect, useState, useId } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { api } from "@bot-prospect/convex";
import { Box, Button, MenuItem, Dialog, Select, Stack, DialogTitle, InputLabel, } from "@mui/material";
import { FormWrapper } from "./index.styled";
import FormInput from "../FormInput";
const CreateNewChatModal = ({ bots, opened, close, }) => {
    var _a;
    const navigate = useNavigate();
    const addChannel = useMutation(api.channels.addChannel);
    const [selectedBot, selectBot] = useState("");
    const { register, setValue, handleSubmit, formState: { errors }, } = useForm({
        defaultValues: {
            name: "",
            botId: "",
        },
        mode: "onChange",
    });
    const selectLabelId = useId();
    const onSubmit = (values) => __awaiter(void 0, void 0, void 0, function* () {
        console.log(values);
        const channelId = yield addChannel({
            botId: values.botId,
            channelName: values.name,
        });
        navigate(`/messages/${channelId}`);
    });
    useEffect(() => {
        if (selectedBot) {
            setValue("botId", selectedBot);
        }
    }, [selectedBot, setValue]);
    return (_jsxs(Dialog, Object.assign({ open: opened, onClose: close }, { children: [_jsx(DialogTitle, { children: "New chat" }), _jsx("form", Object.assign({ onSubmit: handleSubmit(onSubmit) }, { children: _jsx(FormWrapper, { children: _jsxs(Stack, Object.assign({ rowGap: 2 }, { children: [_jsx(FormInput, Object.assign({ label: "Name", placeholder: "Trying a new opener" }, register("name", {
                                required: {
                                    value: true,
                                    message: "Need a chat name so you can remember later!",
                                },
                            }), { error: !!errors.name, helperText: (_a = errors.name) === null || _a === void 0 ? void 0 : _a.message })), _jsxs(Stack, Object.assign({ direction: "column", spacing: 1 }, { children: [_jsx(InputLabel, Object.assign({ id: selectLabelId }, { children: "Title" })), _jsx(Select, Object.assign({ labelId: selectLabelId, inputProps: { "aria-label": "Without label" }, displayEmpty: true, value: selectedBot, onChange: (e) => e.target && selectBot(e.target.value) }, { children: bots.map((bot, i) => (_jsx(MenuItem, Object.assign({ value: bot._id, sx: { fontSize: 18 } }, { children: bot.name }), i))) }))] })), _jsx(Box, Object.assign({ alignSelf: "center" }, { children: _jsx(Button, Object.assign({ type: "submit", variant: "contained", size: "large" }, { children: "Start chatting" })) }))] })) }) }))] })));
};
export default CreateNewChatModal;
