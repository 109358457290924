import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Drawer, List, ListItem, Toolbar, useTheme, } from "@mui/material";
import { IconLogout } from "@tabler/icons-react";
import { useClerk } from "@clerk/clerk-react";
import { UserIcon } from "../../icons/UserIcon";
import { BillingIcon } from "../../icons/BillingIcon";
import { HomeIcon } from "../../icons/HomeIcon";
// import { SupportIcon } from "../../icons/SupportIcon";
import { NavLink } from "react-router-dom";
import { StyledListItemText, SidebarIcon, NavigationButton } from "./Sidebar.styled";
const SidebarContainer = ({ children, width }) => {
    const theme = useTheme();
    return (_jsxs(Drawer, Object.assign({ variant: "permanent", sx: {
            width: width,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
                width: width,
                border: 0,
                boxSizing: "border-box",
                backgroundColor: theme.palette.background.default,
            },
        } }, { children: [_jsx(Toolbar, { sx: { my: 3 } }), children] })));
};
const navigationList = [
    { title: "Home", link: "/", icon: _jsx(HomeIcon, {}) },
    { title: "My account", link: "/settings", icon: _jsx(UserIcon, {}) },
    { title: "Billing", link: "/billing", icon: _jsx(BillingIcon, {}) },
    // { title: "Support", link: "/support", icon: <SupportIcon /> },
];
const Sidebar = ({ width }) => {
    const { signOut } = useClerk();
    return (_jsx(SidebarContainer, Object.assign({ width: width }, { children: _jsxs(List, { children: [navigationList.map((navItem, i) => (_jsx(ListItem, { children: _jsxs(NavigationButton, Object.assign({ component: NavLink, to: navItem.link }, { children: [_jsx(SidebarIcon, { children: navItem.icon }), _jsx(StyledListItemText, { children: navItem.title })] })) }, i))), _jsx(ListItem, { children: _jsxs(NavigationButton, Object.assign({ onClick: (event) => signOut() }, { children: [_jsx(SidebarIcon, { children: _jsx(IconLogout, {}) }), _jsx(StyledListItemText, { children: "Logout" })] })) })] }) })));
};
export default Sidebar;
