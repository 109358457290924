import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Stack, Avatar, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { MessageText, StyledMessageCard } from "./MessageCard.styled";
import botPlaceholderImage from "../../images/bot-avatar.png";
export const MessageCard = ({ channel }) => {
    const navigate = useNavigate();
    return (_jsxs(StyledMessageCard, Object.assign({ onClick: () => {
            navigate(`/messages/${channel["_id"]}`);
        } }, { children: [_jsxs(Stack, Object.assign({ direction: "row", columnGap: 0.5, alignItems: "center" }, { children: [_jsx(Avatar, Object.assign({ sx: { width: 52, height: 52 }, src: botPlaceholderImage, alt: channel["botName"] ? channel["botName"] : "Bot" }, { children: channel["botName"] ? channel["botName"][0] : "B" })), _jsx(Typography, Object.assign({ component: "div", variant: "h6" }, { children: channel["botName"] }))] })), _jsx(MessageText, Object.assign({ variant: "body1" }, { children: channel["lastMessage"] }))] })));
};
