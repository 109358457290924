import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef } from "react";
import { Typography } from "@mui/material";
import { BotChatBubble, UserChatBubble, StyledMessageArea } from "./index.styled";
import dayjs from "dayjs";
const MessageArea = ({ time, children, user = false }) => {
    const formattedTime = dayjs(time).format('hh:mm A');
    return (_jsxs(StyledMessageArea, Object.assign({ user: user }, { children: [children, _jsx(Typography, Object.assign({ variant: 'body2', component: 'div', px: 2.5 }, { children: formattedTime }))] })));
};
const ChatMessage = ({ message, shouldTalk, onFinishedTalking, }) => {
    const audioRef = useRef(null);
    useEffect(() => {
        if (audioRef.current) {
            audioRef.current.onended = () => {
                onFinishedTalking();
            };
        }
    }, [audioRef, onFinishedTalking]);
    return message.role === "user" ? (_jsx(MessageArea, Object.assign({ time: message.createdDate, user: true }, { children: _jsx(UserChatBubble, { children: message.content }) }))) : (_jsxs(MessageArea, Object.assign({ time: message.createdDate }, { children: [_jsx(BotChatBubble, Object.assign({ shouldTalk: shouldTalk }, { children: message.content })), message.audioUrl && shouldTalk && (_jsx("audio", { ref: audioRef, src: message.audioUrl, autoPlay: true }))] })));
};
export default ChatMessage;
