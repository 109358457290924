import { jsx as _jsx } from "react/jsx-runtime";
import { createBrowserRouter } from "react-router-dom";
import AuthLayout from "./components/AuthLayout";
import AddBot from "./pages/add-bot";
import Billing from "./pages/billing";
import Home from "./pages/home";
import Messages from "./pages/messages";
import Onboarding from "./pages/onboarding";
import Settings from "./pages/settings";
import SignInPage from "./pages/sign-in";
import SignUpPage from "./pages/sign-up";
const router = createBrowserRouter([
    {
        path: "sign-in/*",
        element: _jsx(SignInPage, {}),
    },
    {
        path: "sign-up/*",
        element: _jsx(SignUpPage, {}),
    },
    {
        element: _jsx(AuthLayout, {}),
        children: [
            {
                path: "/",
                element: _jsx(Home, {}),
            },
            {
                path: "add-bot",
                element: _jsx(AddBot, {}),
            },
            {
                path: "settings",
                element: _jsx(Settings, {}),
            },
            {
                path: "billing",
                element: _jsx(Billing, {}),
            },
            {
                path: "messages/:channelId",
                element: _jsx(Messages, {}),
            },
            {
                path: "onboarding",
                element: _jsx(Onboarding, {})
            }
        ],
    },
]);
export default router;
