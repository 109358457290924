import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { IconPencil } from "@tabler/icons-react";
import { useAction, useQuery } from "convex/react";
import { useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { api } from "@bot-prospect/convex";
import { Box, Button, InputAdornment, TextField, Stack, styled, } from "@mui/material";
import ChatMessage from "../components/ChatMessage";
import { BotChatHeader } from "../components/BotChatHeader";
import Layout from "../components/Layout";
import { toTitle } from "@bot-prospect/common";
import LoadingScreen from "../components/LoadingScreen";
const chatFixedWidth = 680;
const SectionContainer = styled(Box)(({ theme }) => `
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    flex-grow: 1;
    padding-left: ${theme.spacing(7)};
`);
const FixedWidthBox = styled(Box)(({ theme }) => `
    width: 100%;
    max-width: ${chatFixedWidth}px;
    display: flex;
    justify-content: center;
    flex-shrink: 0;
    padding: ${theme.spacing(2)} 0;
`);
const ChatContainer = styled(Box) `
  width: 100%;
  flex-direction: column;
  flex-basis: 400px;
  flex-grow: 1;
  flex-shrink: 1;
  overflow: auto;
`;
// TODO reenable when we enable audio again
// const MessagesWithAudioList = ({ channelId }: { channelId: string }) => {
//   const messages = useQuery(api.messages.getMessages, {
//     channelId: channelId,
//   });
//   const [finishedTalkingMessages, setFinishedTalkingMessages] = useState<string[]>([]);
//   const [firstLoadLastMessage, setFirstLoadLastMessage] =
//     useState<Message | null>(null);
//   useEffect(() => {
//     if (!firstLoadLastMessage && messages) {
//       setFirstLoadLastMessage(messages[0]);
//     }
//   }, [firstLoadLastMessage, messages]);
//   return (
//     <>
//       {messages &&
//         messages.map((message, i) => {
//           const shouldTalk = !!(
//             firstLoadLastMessage &&
//             message.createdDate > firstLoadLastMessage.createdDate
//             && !finishedTalkingMessages.includes(message.id)
//           );
//           return (
//             <ChatMessage key={i} message={message} shouldTalk={shouldTalk} onFinishedTalking={() =>
//               setFinishedTalkingMessages([...finishedTalkingMessages, message.id])
//             } />
//           );
//         })}
//     </>
//   );
// };
const MessageList = ({ messages }) => {
    return (_jsx(Box, Object.assign({ display: "flex", flexDirection: "column-reverse", width: chatFixedWidth }, { children: messages &&
            messages.map((message, i) => {
                return (_jsx(ChatMessage, { message: message, shouldTalk: false, onFinishedTalking: () => { } }, i));
            }) })));
};
const Messages = () => {
    const { channelId } = useParams();
    const [message, setMessage] = useState("");
    const resetMessage = () => setMessage("");
    const sendMessage = useAction(api.messages.sendMessage);
    const bot = useQuery(api.bots.getBotForChannel, { channelId: channelId });
    const messages = useQuery(api.messages.getMessages, {
        channelId: channelId || "blah",
    });
    const chatContainerRef = useCallback((node) => {
        if (node && messages) {
            node.scrollTop = node.scrollHeight;
        }
    }, [messages]);
    if (!bot && !channelId) {
        return _jsx(LoadingScreen, {});
    }
    return (_jsx(Layout, { children: _jsxs(SectionContainer, { children: [_jsx(FixedWidthBox, { children: _jsx(BotChatHeader, { name: bot === null || bot === void 0 ? void 0 : bot.name, title: toTitle(bot === null || bot === void 0 ? void 0 : bot.title) }) }), _jsx(ChatContainer, Object.assign({ id: "chat-container", ref: chatContainerRef }, { children: messages && _jsx(MessageList, { messages: messages }) })), _jsx(FixedWidthBox, Object.assign({ component: "form", onSubmit: (e) => {
                        e.preventDefault();
                        if (message !== "") {
                            sendMessage({ channelId: channelId || "", content: message });
                            resetMessage();
                        }
                    } }, { children: _jsx(TextField, { fullWidth: true, value: message, placeholder: "Enter a message", onChange: (e) => setMessage(e.currentTarget.value), InputProps: {
                            startAdornment: (_jsx(InputAdornment, Object.assign({ position: "start" }, { children: _jsx(IconPencil, {}) }))),
                            endAdornment: (_jsx(Stack, Object.assign({ direction: "row", alignItems: "center" }, { children: _jsx(Button, Object.assign({ color: "warning", variant: "contained", type: "submit" }, { children: "Send" })) }))),
                        } }) }))] }) }));
};
export default Messages;
