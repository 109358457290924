import { createTheme, darken, lighten } from "@mui/material";
import { outlinedInputClasses } from '@mui/material/OutlinedInput';
// 'Theme composition: using theme options to define other options' -- https://mui.com/material-ui/customization/theming/#createtheme-options-args-theme
let theme = createTheme({
    typography: {
        allVariants: {
            fontFamily: `"Inter"`
        },
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 500,
        fontWeightBold: 600,
        fontSize: 14,
        fontFamily: [
            `"Inter"`,
            'sans-serif',
        ].join(','),
        h1: { fontSize: '4.1875rem', lineHeight: '120%' },
        h2: { fontSize: '3.3125rem', lineHeight: '120%' },
        h3: { fontSize: '2.6875rem', lineHeight: '120%', fontWeight: 500 },
        h4: { fontSize: '2.125rem', lineHeight: '120%' },
        h5: { fontSize: '1.6875rem', lineHeight: '120%', fontWeight: 500 },
        h6: { fontSize: '1.375rem', lineHeight: '120%', fontWeight: 600 },
        subtitle1: { fontSize: '1.125rem', lineHeight: '120%', fontWeight: 400 },
        subtitle2: { fontSize: '1rem', lineHeight: '120%', fontWeight: 500 },
        body2: { fontSize: '0.875rem', lineHeight: '120%', fontWeight: 400 },
        body1: { fontSize: '1.125rem', lineHeight: '150%', fontWeight: 400 },
        caption: { fontSize: '0.875rem', lineHeight: '120%' },
    },
    shape: {
        borderRadius: 10
    },
    palette: {
        primary: {
            light: '#F79A9B',
            main: '#f15152',
        },
        secondary: {
            light: '#F1EFFB',
            main: '#715AD9',
        },
        info: {
            main: '#9ED0E6',
        },
        warning: {
            light: '#FCE9B8',
            main: '#F7C953',
        },
        success: {
            light: '#E4F8BF',
            main: '#BEEE62'
        },
        contrastThreshold: 3.45,
        background: {
            paper: '#FEFEFD',
            default: '#F7F3EB'
        },
        common: {
            black: '#000000'
        }
    },
});
theme = createTheme(theme, {
    components: {
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    color: theme.palette.common.black,
                    fontSize: 18,
                    lineHeight: '150%'
                }
            }
        },
        MuiSlider: {
            styleOverrides: {
                root: {
                    color: theme.palette.common.black,
                    height: 2
                },
                thumb: {
                    backgroundColor: theme.palette.primary.main,
                    height: 25,
                    width: 25,
                    border: '2px solid currentColor',
                    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
                        boxShadow: 'inherit',
                    },
                    '&:before': {
                        display: 'none',
                    },
                },
                track: {
                    border: 'none',
                },
                rail: {
                    color: theme.palette.common.black,
                }
            }
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    '--TextField-brandBorderColor': theme.palette.common.black,
                    '--TextField-brandBorderHoverColor': theme.palette.common.black,
                    '--TextField-brandBorderFocusedColor': theme.palette.common.black,
                    '& label.Mui-focused': {
                        color: 'var(--TextField-brandBorderFocusedColor)',
                    },
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                notchedOutline: {
                    borderColor: 'var(--TextField-brandBorderColor)',
                    borderWidth: 1.5,
                },
                root: {
                    [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
                        borderColor: 'var(--TextField-brandBorderHoverColor)',
                    },
                    [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
                        borderColor: 'var(--TextField-brandBorderFocusedColor)',
                    },
                    '& input': {
                        backgroundColor: theme.palette.background.paper,
                        borderRadius: 10,
                    },
                    fontSize: 18,
                    backgroundColor: theme.palette.background.paper,
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                root: {
                    backgroundColor: theme.palette.background.paper,
                }
            }
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    // fontSize: '0.875rem', -- theme.typography.caption.fontSize - is used by default
                    marginTop: theme.spacing(0.5)
                }
            }
        },
        MuiButton: {
            variants: [
                {
                    props: { variant: 'default' },
                    style: {
                        textTransform: 'none',
                        fontWeight: 600,
                        lineHeight: '150%',
                        color: theme.palette.common.black,
                        backgroundColor: theme.palette.background.paper,
                        borderWidth: 1.5,
                        borderStyle: 'solid',
                        borderColor: theme.palette.common.black,
                        padding: '7px 20px',
                        boxShadow: 'none',
                        '&:hover': {
                            backgroundColor: darken(theme.palette.background.paper, theme.palette.action.hoverOpacity),
                            borderColor: lighten(theme.palette.common.black, theme.palette.action.hoverOpacity),
                            color: lighten(theme.palette.common.black, theme.palette.action.hoverOpacity)
                        }
                    },
                },
            ],
            styleOverrides: {
                contained: {
                    borderWidth: 1.5,
                    borderStyle: 'solid',
                    borderColor: theme.palette.common.black,
                    boxShadow: 'none',
                    textTransform: 'none'
                },
                containedSizeLarge: {
                    borderWidth: 1.5,
                    borderStyle: 'solid',
                    borderColor: theme.palette.common.black,
                    boxShadow: 'none',
                    textTransform: 'none',
                    padding: '12px 32px',
                    fontSize: 22,
                    lineHeight: '130%'
                }
            }
        },
        MuiDialog: {
            styleOverrides: {
                root: {
                    borderWidth: 2,
                    borderStyle: 'solid',
                    borderColor: theme.palette.common.black,
                    boxShadow: theme.shadows[24],
                    '& .MuiDialog-paper': {
                        backgroundColor: theme.palette.background.default,
                    }
                }
            }
        }
    }
});
export { theme };
