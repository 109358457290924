import { ButtonBase, DialogContent, Paper, styled } from "@mui/material";
const StyledTextButton = styled(ButtonBase)(({ theme }) => `
  font-size: ${theme.typography.h6.fontSize};
  font-weight: ${theme.typography.fontWeightRegular};
  line-height: 130%;
  text-decoration: underline;
  text-underline-position: under;
  &.Mui-disabled {
    color: ${theme.palette.grey[300]};
  };
`);
const BotsContainer = styled(Paper) `
  border-style: solid;
  border-width: 1.5px;
  border-color: #000000;
  border-radius: 10px;
  padding: 1.75em 1em;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  width: 100%;
`;
const FormWrapper = styled(DialogContent)(({ theme }) => `
    width: 400px;
  };
`);
export { StyledTextButton, BotsContainer, FormWrapper };
