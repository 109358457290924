import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useDisclosure } from "@mantine/hooks";
import { useQuery } from "convex/react";
import { useEffect, useState } from "react";
import { api } from "@bot-prospect/convex";
import { Box, Button, Container, Grid, Stack, Typography, } from "@mui/material";
import BotCard from "../components/BotCards/BotCard";
import AddBotCard from "../components/BotCards/AddBotCard";
import { MessageCard } from "../components/MessageCard";
import { BotsContainer, StyledTextButton, } from "../components/HomePage/index.styled";
import CreateNewChatModal from "../components/HomePage/CreateNewChatModal";
import Layout from "../components/Layout";
const Home = () => {
    const [opened, { open, close }] = useDisclosure(false);
    const channels = useQuery(api.channels.getChannels);
    const bots = useQuery(api.bots.getBots);
    const [visibleBots, setVisibleBots] = useState([]);
    const [expanded, setExpanded] = useState(false);
    useEffect(() => {
        if (bots !== undefined && bots.length > 0) {
            setVisibleBots(bots.slice(0, 3));
        }
    }, [bots]);
    const expandBotsContainer = () => {
        setVisibleBots(bots);
        setExpanded(true);
    };
    const collapseBotsContainer = () => {
        setVisibleBots(bots === null || bots === void 0 ? void 0 : bots.slice(0, 3));
        setExpanded(false);
    };
    return (_jsxs(Layout, { children: [bots && _jsx(CreateNewChatModal, { bots: bots, opened: opened, close: close }), _jsx(Container, Object.assign({ maxWidth: "lg" }, { children: _jsxs(Stack, Object.assign({ direction: "column" }, { children: [_jsxs(BotsContainer, Object.assign({ elevation: 0 }, { children: [_jsxs(Stack, Object.assign({ direction: "row", justifyContent: "space-between", alignItems: "baseline", sx: { px: 1 } }, { children: [_jsx(Typography, Object.assign({ component: "div", variant: "h6" }, { children: "Bots" })), _jsx(StyledTextButton, Object.assign({ disabled: ((bots === null || bots === void 0 ? void 0 : bots.length) || 0) <= 3, onClick: expanded ? collapseBotsContainer : expandBotsContainer }, { children: expanded ? "Hide" : "See all" }))] })), _jsx(Box, Object.assign({ sx: bots && visibleBots && visibleBots.length > 11
                                        ? { px: 1, maxHeight: "890px", overflowY: "auto" }
                                        : { px: 1 } }, { children: _jsxs(Grid, Object.assign({ container: true, alignItems: "stretch", spacing: 3 }, { children: [_jsx(AddBotCard, {}), bots &&
                                                visibleBots &&
                                                visibleBots.map((bot, i) => (_jsx(BotCard, { bot: bot }, i)))] })) }))] })), channels && (_jsxs(Stack, Object.assign({ direction: "column", rowGap: 3, p: 2.5 }, { children: [_jsx(Typography, Object.assign({ component: "div", variant: "h6" }, { children: "Chats" })), _jsx(Stack, Object.assign({ direction: "column", rowGap: 3.5 }, { children: channels.map((channel, i) => (_jsx(MessageCard, { channel: channel }, i))) })), _jsx(Box, Object.assign({ alignSelf: "center" }, { children: _jsx(Button, Object.assign({ variant: "contained", size: "large", onClick: open }, { children: "New chat" })) }))] })))] })) }))] }));
};
export default Home;
