var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { InputLabel, Stack, TextField } from "@mui/material";
import { forwardRef, useId } from "react";
const FormInput = forwardRef((_a, ref) => {
    var { label = '', error } = _a, props = __rest(_a, ["label", "error"]);
    const id = useId();
    return (_jsxs(Stack, Object.assign({ direction: "column", spacing: 1 }, { children: [_jsx(InputLabel, Object.assign({ error: error, htmlFor: id }, { children: label })), _jsx(TextField, Object.assign({ id: id, hiddenLabel: true, error: error, inputRef: ref }, props))] })));
});
export default FormInput;
