import { jsx as _jsx } from "react/jsx-runtime";
import { ListItemButton, ListItemIcon, ListItemText, styled, } from "@mui/material";
const StyledListItemText = styled(ListItemText)(({ theme }) => `
  margin: 0;
  & .MuiTypography-root {
    font-size: ${theme.typography.subtitle1.fontSize};
    font-weight: ${theme.typography.fontWeightMedium};
    line-height: 150%;
  }`);
const StyledListItemButton = styled(ListItemButton)(({ theme }) => `
    padding: 10px 20px;
    border-radius: 50px;
    border: 1.5px solid transparent;
    &.active {
      background-color: ${theme.palette.warning.main};
      border-color: ${theme.palette.common.black};
    };
  `);
const NavigationButton = (props) => {
    return (_jsx(StyledListItemButton, Object.assign({}, props, { children: props.children })));
};
const SidebarIcon = styled(ListItemIcon)(({ theme }) => `
  minWidth: 30px;
  marginRight: 8px;
  fontSize: 22px;
  & .MuiSvgIcon-root {
    fill: ${theme.palette.background.default};
    fontSize: inherit;
  };
`);
export { StyledListItemText, SidebarIcon, NavigationButton };
