import { api, useQuery } from "@bot-prospect/convex";
import { useEffect, useState } from "react";
export const useUser = () => {
    const user = useQuery(api.users.getCurrentUser);
    const [currentUser, setCurrentUser] = useState(null);
    useEffect(() => {
        if (user) {
            setCurrentUser({
                id: user._id,
                clerkId: user.external_id,
                onboarded: user.onboarded,
                firstName: user.first_name,
                lastName: user.last_name,
                email: user.email,
            });
        }
    }, [user]);
    return currentUser;
};
