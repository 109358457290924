export var Objection;
(function (Objection) {
    Objection["TOO_EXPENSIVE"] = "Too expensive";
    Objection["NOT_THE_RIGHT_TIME"] = "Not the right time";
    Objection["NO_BUDGET"] = "No budget";
    Objection["LACK_OF_NEED"] = "Not sure how this can help me";
    Objection["LACK_OF_URGENCY"] = "This is not important to me right now";
    Objection["NEED_TO_USE_BUDGET_SOMEWHERE_ELSE"] = "Need to use the budget somewhere else";
    Objection["ALREADY_WITH_COMPETITOR"] = "Already with a competitor";
    Objection["CAN_GET_CHEAPER_SOMEWHERE_ELSE"] = "Can get cheaper option";
    Objection["HAPPY_WITH_COMPETITOR"] = "Happy with competitor";
    Objection["COMPETITOR_SAY_THIS_ABOUT_YOU"] = "Competitor says something about you";
    Objection["NOT_AUTHORIZED"] = "Not authorized to sign off on this";
    Objection["CANNOT_SELL_INTERNALLY"] = "Cannot sell this to their stake holders";
    Objection["BUYER_NOT_CONVINCED"] = "The buyer is not convinced";
    Objection["BEING_BOUGHT"] = "Currently being bought";
    Objection["DOWNSIZING"] = "Currently downsizing";
    Objection["TOO_MUCH_RIGHT_NOW"] = "Too much going on right now";
    Objection["PART_OF_BUYING_GROUP"] = "Part of buying group";
    Objection["NEVER_HEARD_OF_COMPANY"] = "Never heard of this company";
    Objection["DOING_GREAT"] = "Doing great in that area";
    Objection["NO_BUSINESS_PLAN"] = "No business plan";
    Objection["NOT_IMPORTANT"] = "Not important right now";
    Objection["COMPLAINTS"] = "Heard complaints from competitor";
    Objection["CANNOT_IMPLEMENT"] = "No capacity to implement";
    Objection["TOO_COMPLICATED"] = "Too complicated";
    Objection["DO_NOT_UNDERSTAND_ME"] = "You do not undersand. I need help with X, not Y";
    Objection["DOES_NOT_HAVE_FEATURE"] = "Your product doesn't have X feature, and we need it.";
    Objection["HAPPY_CURRENTLY"] = "We're happy the way things are.";
    Objection["DO_NOT_SEE_ROI"] = "I don't see the potential for ROI.";
    Objection["FAD"] = "It's just a fad.";
    Objection["DOES_NOT_WORK_CURRENT_SETUP"] = "Your product doesn't work with our current set-up.";
    Objection["TOO_BUSY"] = "Too busy right now";
    Objection["JUST_SEND_ME_INFORMATION"] = "Just send me some information.";
    Objection["CONTACT_ME_LATER"] = "Contact me later";
    Objection["NOT_RESPONSIBLE"] = "I'm not responsible for making these decisions.";
})(Objection || (Objection = {}));
export var ProspectTitle;
(function (ProspectTitle) {
    ProspectTitle["EXECUTIVE"] = "Executive";
    ProspectTitle["MIDDLE_MANAGEMENT"] = "Middle Manager";
    ProspectTitle["INTERN"] = "Intern";
})(ProspectTitle || (ProspectTitle = {}));
export const toTitle = (key) => {
    if (!key) {
        return undefined;
    }
    if (ProspectTitle[key]) {
        return ProspectTitle[key];
    }
    throw new Error('Invalid title key provided');
};
export var Disposition;
(function (Disposition) {
    Disposition["AGREEABLE"] = "AGREEABLE";
    Disposition["DISAGREEABLE"] = "DISAGREEABLE";
})(Disposition || (Disposition = {}));
export var HappinessLevel;
(function (HappinessLevel) {
    HappinessLevel["ASSHOLE"] = "ASSHOLE";
    HappinessLevel["BAD_DAY"] = "BAD_DAY";
    HappinessLevel["REGULAR"] = "REGULAR";
    HappinessLevel["FRIENDLY"] = "FRIENDLY";
    HappinessLevel["BUBBLY"] = "BUBBLY";
    HappinessLevel["ANGEL"] = "ANGEL";
})(HappinessLevel || (HappinessLevel = {}));
;
