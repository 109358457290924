import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { theme } from "./theme";
// Supports weights 100-900
import '@fontsource/inter/300.css';
import '@fontsource/inter/400.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/600.css';
import '@fontsource/inter/700.css';
const App = ({ children }) => {
    return (_jsxs(ThemeProvider, Object.assign({ theme: theme }, { children: [_jsx(CssBaseline, {}), children] })));
};
export default App;
