import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Stack, Typography, CardContent, useTheme, CardActions, Button, Grid, } from "@mui/material";
import { StyledBotCard } from "./BotCard.styles";
import { useNavigate } from "react-router-dom";
const AddBotCard = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    return (_jsx(Grid, Object.assign({ item: true, xs: 3, minWidth: '200px', maxWidth: '230px' }, { children: _jsxs(StyledBotCard, Object.assign({ variant: "outlined", sx: { backgroundColor: theme.palette.secondary.light } }, { children: [_jsx(CardContent, { children: _jsxs(Stack, Object.assign({ direction: "column", rowGap: 0.5, textAlign: "center", alignItems: "center" }, { children: [_jsx(Typography, Object.assign({ variant: "h6", lineHeight: "130%" }, { children: "Add a new bot" })), _jsx(Typography, Object.assign({ variant: "body2", lineHeight: "150%" }, { children: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin euismod ipsum et tincidunt elementum. Aliquam efficitur metus tincidunt elementum." }))] })) }), _jsx(CardActions, { children: _jsx(Button, Object.assign({ variant: "contained", onClick: () => {
                            navigate(`/add-bot`);
                        } }, { children: "Add bot" })) })] })) })));
};
export default AddBotCard;
