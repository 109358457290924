import { Card, styled } from "@mui/material";
const StyledBotCard = styled(Card)(({ theme }) => `
  border-radius: 8px;
  border: 1.5px solid ${theme.palette.common.black};
  min-height: 260px;
  height: 100%;
  display: flex;
  flex-direction: column;

  & .MuiCardContent-root {
    padding: 24px 16px 10px;
    flex-grow: 1;
  };

  & .MuiCardActions-root {
    padding: 8px 16px 28px;
    justify-content: center;

    & .MuiButton-root {
      font-weight: 600;
      line-height: 150%;
      padding: 7px 20px;
    }
  }
`);
export { StyledBotCard };
