import { Card, Typography, styled } from "@mui/material";
const StyledMessageCard = styled(Card)(({ theme }) => `
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  padding: 20px;
  border: 1.5px solid ${theme.palette.common.black};
  border-radius: 10px;
  &:hover {
    cursor: pointer;
  }
  `);
const MessageText = styled(Typography)(({ theme }) => `
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`);
export { MessageText, StyledMessageCard };
