import { Box, styled } from "@mui/material";
const StyledBubble = styled(Box)(({ theme }) => `
  border-radius: ${theme.shape.borderRadius}px;
  border: 1.5px solid;
  border-color: ${theme.palette.common.black};
  padding: 15px 20px;
  max-width: 446px;
  font-size: ${theme.typography.body1.fontSize};
  line-height: ${theme.typography.body1.lineHeight};
`);
const UserChatBubble = styled(StyledBubble)(({ theme }) => `
  background-color: ${theme.palette.primary.main};
  color: ${theme.palette.primary.contrastText};
`);
const BotChatBubble = styled(StyledBubble, {
    shouldForwardProp: (prop) => prop !== "shouldTalk",
})(({ theme, shouldTalk }) => `
  background-color: ${shouldTalk ? theme.palette.secondary.light : theme.palette.background.paper};
`);
const StyledMessageArea = styled(Box, {
    shouldForwardProp: (prop) => prop !== "user",
})(({ theme, user }) => `
  display: flex;
  flex-direction: column;
  row-gap: ${theme.spacing(0.5)};
  align-items: ${user ? "flex-end" : "flex-start"};
  margin-bottom: ${theme.spacing(2)};
  margin-right: ${user ? '2px' : 0};
`);
export { UserChatBubble, BotChatBubble, StyledMessageArea };
