import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AppBar, Avatar, Box, ButtonBase, IconButton, Toolbar, Typography, useTheme, } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useUser } from "../hooks/useUser";
const User = ({ firstName, onClick }) => {
    const theme = useTheme();
    return (_jsx(IconButton, Object.assign({ sx: { p: 0, mx: 1 }, onClick: onClick }, { children: _jsx(Avatar, Object.assign({ variant: "circular", sx: {
                width: 49,
                height: 49,
                backgroundColor: theme.palette.common.white,
                color: theme.palette.text.primary,
                fontSize: "1.375rem",
                fontWeight: 600,
            } }, { children: (firstName === null || firstName === void 0 ? void 0 : firstName[0]) || "J" })) })));
};
const Logo = () => {
    const navigate = useNavigate();
    return (_jsx(Box, Object.assign({ sx: { flexGrow: 1 } }, { children: _jsxs(ButtonBase
        // disableTouchRipple
        , Object.assign({ 
            // disableTouchRipple
            onClick: (event) => navigate("/") }, { children: [_jsx(Box, Object.assign({ sx: {
                        backgroundColor: "#d9d9d9",
                        width: 40,
                        height: 40,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }, mr: 2 }, { children: "\uD83E\uDD16" })), _jsx(Typography, Object.assign({ variant: "h6", fontWeight: 550, component: "div" }, { children: "Bot Prospect" }))] })) })));
};
const Header = () => {
    const user = useUser();
    const theme = useTheme();
    const navigate = useNavigate();
    return (_jsx(AppBar, Object.assign({ color: "default", sx: {
            zIndex: (theme) => theme.zIndex.drawer + 10,
            boxShadow: 0,
            backgroundColor: theme.palette.background.default,
        } }, { children: _jsxs(Toolbar, Object.assign({ sx: { my: 3 } }, { children: [_jsx(Logo, {}), _jsxs(Toolbar, Object.assign({ disableGutters: true, sx: { columnGap: 1 } }, { children: [_jsxs(Typography, { children: ["Welcome ", user === null || user === void 0 ? void 0 : user.firstName, "!"] }), _jsx(User, { firstName: user === null || user === void 0 ? void 0 : user.firstName, onClick: () => {
                                navigate("/settings");
                            } })] }))] })) })));
};
export default Header;
